<template>
  <div>
    <vue-base-dialog
      :dialogConfig="dialogConfig"
      class="selfCreateDialog"
      ref="childDialog"
      @close="parentModalFunc"
    >
      <div class="dialog-content">
        <div class="top-progress-box">
          <div :class="step >= 1 ? 'circular-box active' : 'circular-box'">
            <div class="icon-box step1"></div>
            <div class="step-text">验证手机号</div>
          </div>
          <div :class="step > 1 ? 'line-box active' : 'line-box'">
            <div class="all-box">
              <div class="dot-one"></div>
              <div class="dot-one two"></div>
              <div class="dot-one"></div>
            </div>
          </div>
          <div :class="step >= 2 ? 'circular-box active' : 'circular-box'">
            <div class="icon-box step2"></div>
            <div class="step-text">绑定新手机号</div>
          </div>
          <div :class="step > 2 ? 'line-box active' : 'line-box'">
            <div class="all-box">
              <div class="dot-one"></div>
              <div class="dot-one two"></div>
              <div class="dot-one"></div>
            </div>
          </div>
          <div :class="step == 3 ? 'circular-box active' : 'circular-box'">
            <div class="icon-box step3"></div>
            <div class="step-text">绑定成功</div>
          </div>
        </div>

        <!-- 验证手机号 -->
        <div v-if="step == 1" class="center-form-box">
          <div class="form-group">
            <div class="form-label">原&nbsp;手&nbsp;机&nbsp;号：</div>
            <div :class="[phoneError && 'error', 'form-control']">
              <div class="input-box">
                <input
                  type="text"
                  @change="changeMobile($event)"
                  v-model="phone"
                  class="form-input"
                  placeholder="请输入原手机号"
                />
              </div>
              <div v-if="phoneError" class="error-info">{{ phoneError }}</div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">图形验证码：</div>
            <div :class="[codeError && 'error', 'form-control']">
              <div class="input-box">
                <input
                  type="text"
                  @change="changeCode($event)"
                  v-model="code"
                  class="form-input"
                  placeholder="请输入图形验证码"
                />
              </div>
              <div class="login-code" @click="loginCode">
                <img v-if="imgCode" :src="imgCode" alt />
              </div>
              <div v-if="codeError" class="error-info">{{ codeError }}</div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              验&nbsp;&nbsp;&nbsp;证&nbsp;&nbsp;&nbsp;码：
            </div>
            <div :class="[smsCodeError && 'error', 'form-control']">
              <div class="input-box">
                <input
                  type="text"
                  @change="changeSmsCode($event)"
                  v-model="smsCode"
                  class="form-input"
                  placeholder="请输入短信验证码"
                />
              </div>
              <div :class="loginSmsClass" @click="loginSms">
                {{ loginSmsText }}
              </div>
              <div v-if="smsCodeError" class="error-info">
                {{ smsCodeError }}
              </div>
            </div>
          </div>
          <div class="confirm-button" @click="sumbitForm">确认修改</div>
        </div>

        <!-- 绑定新手机号 -->
        <div v-if="step == 2" class="center-form-box">
          <div class="form-group">
            <div class="form-label">新&nbsp;手&nbsp;机&nbsp;号：</div>
            <div :class="[phoneError && 'error', 'form-control']">
              <div class="input-box">
                <input
                  type="text"
                  @change="changeMobile($event)"
                  v-model="phone"
                  class="form-input"
                  placeholder="请输入新手机号"
                />
              </div>
              <div v-if="phoneError" class="error-info">{{ phoneError }}</div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">图形验证码：</div>
            <div :class="[codeError && 'error', 'form-control']">
              <div class="input-box">
                <input
                  type="text"
                  @change="changeCode($event)"
                  v-model="code"
                  class="form-input"
                  placeholder="请输入图形验证码"
                />
              </div>
              <div class="login-code" @click="loginCode">
                <img v-if="imgCode" :src="imgCode" alt />
              </div>
              <div v-if="codeError" class="error-info">{{ codeError }}</div>
            </div>
          </div>
          <div class="form-group">
            <div class="form-label">
              验&nbsp;&nbsp;&nbsp;证&nbsp;&nbsp;&nbsp;码：
            </div>
            <div :class="[smsCodeError && 'error', 'form-control']">
              <div class="input-box">
                <input
                  type="text"
                  @change="changeSmsCode($event)"
                  v-model="smsCode"
                  class="form-input"
                  placeholder="请输入短信验证码"
                />
              </div>
              <div :class="loginSmsClass" @click="loginSms">
                {{ loginSmsText }}
              </div>
              <div v-if="smsCodeError" class="error-info">
                {{ smsCodeError }}
              </div>
            </div>
          </div>
          <div class="confirm-button" @click="sumbitForm">确认绑定</div>
        </div>

        <!-- 绑定成功 -->
        <div v-if="step == 3" class="complete-form-box">
          <img
            class="confirm-image"
            src="../../../assets/site/user/success-icon.png"
          />
          <div class="new-phone">
            您的手机号已更换为{{ successPhone }}，同时您的账号为此手机号。
          </div>
          <div class="confirm-button" @click="backUserCenter">返回个人信息</div>
        </div>
      </div>
    </vue-base-dialog>
  </div>
</template>

<script>
import VueBaseDialog from "@/components/VueBaseDialog";
import services from "../../../config/services";
import { mapActions, mapState } from "vuex";
export default {
  name: "changePhoneModal",
  props: {
    dialogConfig: {
      type: Object,
    },
  },
  components: {
    VueBaseDialog,
  },
  data() {
    return {
      step: 1,
      successPhone: "",
      services: services,
      imgCode: "",
      loginSmsNum: 60,
      loginSmsText: "获取验证码",
      loginSmsClass: "login-sms",
      phone: "",
      code: "",
      smsCode: "",
      phoneError: "",
      codeError: "",
      smsCodeError: "",
      captchaKey: "",
    };
  },
  mounted() {
    this.loginCode();
  },
  computed: {
    ...mapState({ appsUserInfo: (state) => state.appsLogin.appsUserInfo }),
  },
  methods: {
    ...mapActions([
      "getAppsImageCaptcha",
      "requestUpdateUserPhone",
      "getAppsSmsCaptcha",
    ]),
    parentModalFunc() {
      if (this.step == 3) {
        this.$emit("refreshData");
      }
    },
    backUserCenter() {
      this.$refs.childDialog.close();
      this.parentModalFunc();
    },
    loginSms() {
      if (this.loginSmsClass === "login-sms") {
        let that = this;
        let params = {};
        if (that.phone) {
          if (/^1[3-9][0-9]{9}$/.test(that.phone)) {
            if (that.step == 2 && that.appsUserInfo.mobile == that.phone) {
              that.phoneError = "新手机号与旧手机号一样";
              return false;
            } else if (
              that.step == 1 &&
              that.appsUserInfo.mobile != that.phone
            ) {
              that.phoneError = "原手机号错误";
              return false;
            } else {
              params.phone = that.phone;
              that.phoneError = "";
            }
          } else {
            this.phoneError = "请输入正确的手机号";
            return false;
          }
        } else {
          this.phoneError = "请输入手机号";
          return false;
        }

        if (this.code) {
          params.code = this.code;
          this.codeError = "";
        } else {
          this.codeError = "请输入图形验证码";
          return false;
        }
        that
          .getAppsSmsCaptcha({
            mobile: params.phone,
            captcha: params.code,
            key: that.captchaKey,
          })
          .then(() => {
            this.loginSmsText = this.loginSmsNum + "s后获取";
            this.loginSmsClass = "login-sms disabled";
            this.loginSmsNum--;
            // 倒计时
            this.interval1 = setInterval(() => {
              if (this.loginSmsNum > 0) {
                this.loginSmsText = this.loginSmsNum + "s后获取";
                this.loginSmsNum--;
              } else {
                this.loginSmsText = "获取验证码";
                this.loginSmsNum = 60;
                this.loginSmsClass = "login-sms";
                clearInterval(this.interval1);
              }
            }, 1000);
          })
          .catch((res) => {
            if (res === 1) this.loginCode();
          });
      }
    },
    async getSmsCode() {
      let that = this;
      let params = {};
      if (that.phone) {
        if (/^1[3-9][0-9]{9}$/.test(that.phone)) {
          if (that.step == 2 && that.appsUserInfo.mobile == that.phone) {
            that.phoneError = "新手机号与旧手机号一样";
            return false;
          } else if (that.step == 1 && that.appsUserInfo.mobile != that.phone) {
            that.phoneError = "原手机号错误";
            return false;
          } else {
            params.phone = that.phone;
            that.phoneError = "";
          }
        } else {
          this.phoneError = "请输入正确的手机号";
          return false;
        }
      } else {
        this.phoneError = "请输入手机号";
        return false;
      }

      if (this.code) {
        params.code = this.code;
        this.codeError = "";
      } else {
        this.codeError = "请输入图形验证码";
        return false;
      }
      return new Promise((reolve, reject) => {
        if (params.code && params.phone) {
          return that
            .getAppsSmsCaptcha({
              mobile: params.phone,
              captcha: params.code,
              key: that.captchaKey,
            })
            .then((res) => {
              if (res.code === "0000") {
                that.$message.success(
                  res.message ? res.message : "短信验证码发送成功"
                );
                reolve(res);
              } else {
                if (res.errors) {
                  this.codeError = res.errors.captcha;
                  this.phoneError = res.errors.mobile;
                }
                that.$message.error(
                  res.message ? res.message : "短信验证码发送失败"
                );
                reject(1);
              }
            });
        }
      });
    },
    // 请求图片验证码
    loginCode() {
      let that = this;
      that.getAppsImageCaptcha().then((res) => {
        if (res.code == "0000") {
          this.imgCode = res.data.img;
          this.captchaKey = res.data.key;
        }
      });
    },
    async sumbitForm() {
      let params = {};
      let that = this;
      if (that.phone) {
        if (/^1[3-9][0-9]{9}$/.test(that.phone)) {
          this.phoneError = "";
          if (that.step == 2 && that.appsUserInfo.mobile == that.phone) {
            that.phoneError = "新手机号与旧手机号一样";
            return false;
          } else if (that.step == 1 && that.appsUserInfo.mobile != that.phone) {
            that.phoneError = "原手机号错误";
            return false;
          } else {
            params.phone = that.phone;
            that.phoneError = "";
          }
        } else {
          this.phoneError = "请输入正确的手机号";
          return false;
        }
      } else {
        this.phoneError = "请输入手机号";
        return false;
      }

      if (this.code) {
        params.code = this.code;
        this.codeError = "";
      } else {
        this.codeError = "请输入图形验证码";
        return false;
      }

      if (this.smsCode) {
        params.smsCode = this.smsCode;
        this.smsCodeError = "";
      } else {
        this.smsCodeError = "请输入短信验证码";
        return false;
      }
      if (this.phoneError || this.codeError || this.smsCodeError) {
        return false;
      } else {
        if (this.step == 1) {
          this.metodCheckPhone({
            _method: "put",
            act: "check", // 类型 check校验 bind绑定
            mobile: that.phone,
            code: that.smsCode,
          });
        } else {
          this.metodModifyPhone({
            _method: "put",
            act: "bind", // 类型 check校验 bind绑定
            mobile: that.phone,
            code: that.smsCode,
          });
        }
      }
    },
    // 验证手机号
    metodCheckPhone(params) {
      let that = this;
      that.requestUpdateUserPhone(params).then((res) => {
        if (res.code === "0000") {
          that.$message.success(res.message ? res.message : "验证成功");
          that.step = 2;
          that.phone = "";
          that.code = "";
          that.codeError = "";
          that.smsCode = "";
          that.phoneError = "";
          that.smsCodeError = "";
          that.loginCode();
          that.loginSmsText = "获取验证码";
          that.loginSmsNum = 60;
          that.loginSmsClass = "login-sms";
          if (that.interval1) {
            clearInterval(that.interval1);
          }
        } else {
          if (res.errors) {
            that.smsCodeError = res.errors.code;
            that.phoneError = res.errors.mobile;
          }
          that.$message.error(res.message ? res.message : "验证失败");
        }
      });
    },
    // 修改手机号
    metodModifyPhone(params) {
      let that = this;
      that.requestUpdateUserPhone(params).then((res) => {
        if (res.code === "0000") {
          that.step = 3;
          that.successPhone = params.mobile;
        } else {
          if (res.errors) {
            that.smsCodeError = res.errors.code;
            that.phoneError = res.errors.mobile;
          }
          that.$message.error(res.message ? res.message : "绑定失败");
        }
      });
    },
    changeMobile(e) {
      let that = this;
      if (e.target.value) {
        if (/^1[3-9][0-9]{9}$/.test(e.target.value)) {
          if (that.step == 2 && that.appsUserInfo.mobile == that.phone) {
            that.phoneError = "新手机号与旧手机号一样";
          } else if (that.step == 1 && that.appsUserInfo.mobile != that.phone) {
            that.phoneError = "原手机号错误";
          } else {
            that.phoneError = "";
          }
        } else {
          that.phoneError = "请输入正确的手机号";
        }
      } else {
        that.phoneError = "请输入手机号";
      }
    },
    changeCode(e) {
      if (e.target.value) {
        this.codeError = "";
      } else {
        this.codeError = "请输入图形验证码";
      }
    },
    changeSmsCode(e) {
      if (e.target.value) {
        this.smsCodeError = "";
      } else {
        this.smsCodeError = "请输入短信验证码";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-content {
  padding: 20px 40px;
  width: 600px;
}
.top-progress-box {
  padding: 20px 0 40px;
  text-align: center;
}
.top-progress-box .circular-box {
  width: 70px;
  height: 70px;
  background: #d6d6d6;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.top-progress-box .circular-box.active {
  background: #c9daff;
}
.top-progress-box .circular-box .icon-box {
  width: 32px;
  height: 41px;
  margin: 14px 0 0 19px;
}
.top-progress-box .circular-box .step-text {
  font-size: 13px;
  color: #666666;
  position: absolute;
  bottom: -30px;
  width: 80px;
  text-align: center;
  left: -5px;
}
.top-progress-box .circular-box .icon-box.step1 {
  background: url("../../../assets/site/user/step1-active.png") no-repeat center;
  background-size: 100% auto;
}
.top-progress-box .circular-box .icon-box.step2 {
  background: url("../../../assets/site/user/step2.png") no-repeat center;
  background-size: 100% auto;
}
.top-progress-box .circular-box.active .icon-box.step2 {
  background: url("../../../assets/site/user/step2-active.png") no-repeat center;
  background-size: 100% auto;
}
.top-progress-box .circular-box .icon-box.step3 {
  background: url("../../../assets/site/user/step3.png") no-repeat center;
  background-size: 100% auto;
}
.top-progress-box .circular-box.active .icon-box.step3 {
  background: url("../../../assets/site/user/step3-active.png") no-repeat center;
  background-size: 100% auto;
}
.top-progress-box .line-box {
  width: 105px;
  height: 2px;
  background: #ffffff;
  border: 1px dotted #d6d6d6;
  position: relative;
  text-align: center;
  display: inline-block;
}
.top-progress-box .line-box.active {
  border: 1px dotted #c9daff;
}
.top-progress-box .line-box .all-box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  top: -12px;
}
.top-progress-box .line-box .all-box .dot-one {
  width: 8px;
  height: 8px;
  background: #d6d6d6;
  border-radius: 50%;
  display: inline-block;
  margin: 5px;
  vertical-align: middle;
}
.top-progress-box .line-box .all-box .dot-one.two {
  width: 10px;
  height: 10px;
}
.top-progress-box .line-box.active .dot-one {
  background: #c9daff;
}
.button {
  margin-top: 10px;
}
.button:hover {
  background: #134bce;
}
.center-form-box {
  margin: 30px auto 0;
  width: 350px;
  box-sizing: border-box;
  .form-group {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    .form-control {
      flex: 1;
      display: flex;
      position: relative;
      border-bottom: 1px solid #e5e5e5;
      border-top: none;
      border-left: none;
      border-right: none;
      .input-box {
        flex: 1;
        input {
          display: inline-block;
          width: 100%;
          border-radius: 0;
          border: none;
          height: 40px;
          line-height: 40px;
          padding: 5px 0;
          box-sizing: border-box;
          color: #333;
          font-size: 14px;
        }
      }
      .login-code {
        width: 86px;
        height: 40px;
        cursor: pointer;
        img {
          width: 100%;
          height: auto;
        }
      }
      .login-sms {
        color: #2462f5;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        margin-left: 10px;
        &.disabled {
          color: #969696;
        }
      }

      .error-info {
        position: absolute;
        bottom: -18px;
        font-size: 12px;
        color: #f73b3b;
        left: 0;
      }
      &.error {
        border-color: #f73b3b;
      }
    }
    .form-label {
      width: 84px;
      color: #333333;
      line-height: 40px;
    }
  }

  .confirm-button {
    padding: 0 20px;
    height: 32px;
    display: inline-block;
    background: #2462f5;
    border-radius: 3px;
    font-size: 14px;
    color: #ecedff;
    line-height: 32px;
    margin: 40px auto 20px;
    cursor: pointer;
  }
}
.complete-form-box {
  margin: 30px auto 0;
  box-sizing: border-box;
  .confirm-image {
    width: 50px;
    margin: 0 auto 30px;
  }
  .new-phone {
    color: #8b8b8b;
    font-size: 14px;
    margin: 0 auto;
  }
  .confirm-button {
    padding: 0 20px;
    height: 32px;
    display: inline-block;
    background: #2462f5;
    border-radius: 3px;
    font-size: 14px;
    color: #ecedff;
    line-height: 32px;
    margin: 50px auto 20px;
    cursor: pointer;
  }
}
</style>